//Global dependencies are all added here
import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '@openfonts/roboto-condensed_all/index.css';
// import '@openfonts/oswald_all/index.css';

import "./styles/style.scss";
import './styles/style.css';

import navigation from "../src/navigation.html";
import footer from "../src/footer.html";
import cookiesPopup from "../src/cookiesPopup.html";

require('@openfonts/roboto-condensed_all');
// require('@openfonts/oswald_all')

//function component(parameters) {}

document.getElementById("navigation").innerHTML = navigation;
document.getElementById("footer").innerHTML = footer;
document.getElementById("cookiesPopup").innerHTML = cookiesPopup;


$(".btn-group >.btn").on("click", function() {
    $(this).addClass("active").siblings().removeClass("active");
})

// make form active only if datenschutz was accepted


$(".filter-button").click(function() {
    var value = $(this).attr('data-filter');

    if (value == "*") {
        //30 = speed of changes
        $('.filter').fadeIn('slow');
    } else {
        $(".filter").not('.' + value).fadeOut('fast');
        $('.filter').filter('.' + value).fadeIn('slow');

    }

    if ($(".filter-button").removeClass("btn-primary")) {
        $(".filter-button").addClass("btn-light")
    }
    $(this).removeClass("btn-light");
    $(this).addClass("btn-primary");
});

export function submitContactForm() {
    if ($('#contactForm')[0].checkValidity()) {
        let contactFormData = new FormData($("#contactForm")[0]);
        contactFormData.append("subject", $(".active").val());
        $("#cardSpinner").css("visibility", "visible");
        $.ajax({
            type: 'POST',
            enctype: 'multipart/form-data',
            url: "sendMail.php",
            data: contactFormData,
            processData: false,
            contentType: false,
            success: contactSubmitCallback
        });
    } else {
        $("#contactForm")[0].reportValidity();
    }
}

function contactSubmitCallback(resultString) {
    $("#cardSpinner").css("visibility", "hidden");
    let result = JSON.parse(resultString);
    $("#contactResponseModalHeader").removeClass();
    if (result.id === "OK") {
        $("#contactResponseModalHeader").addClass("modal-header alert alert-primary mb-0");
    } else {
        $("#contactResponseModalHeader").addClass("modal-header alert alert-danger mb-0");
    }
    $("#contactResponseModalTitle").text(result.message);
    $('#contactResponseModal').modal('show');

    if (result.id === "OK") {
        $('#contactForm')[0].reset();
    }
}


// Cookies banner
/* @description Shows the cookie banner
 */
function showCookieBanner() {
    let cookieBanner = document.getElementById("cb-cookie-banner");
    cookieBanner.style.display = "block";
}

/**
 * @description Hides the Cookie banner and saves the value to localstorage
 */
function hideCookieBanner() {
    localStorage.setItem("cb_isCookieAccepted", "yes");

    let cookieBanner = document.getElementById("cb-cookie-banner");
    cookieBanner.style.display = "none";
}

/**
 * @description Checks the localstorage and shows Cookie banner based on it.
 */
function initializeCookieBanner() {
    let isCookieAccepted = localStorage.getItem("cb_isCookieAccepted");
    if (isCookieAccepted === null) {
        localStorage.setItem("cb_isCookieAccepted", "no");
        showCookieBanner();
    }
    if (isCookieAccepted === "no") {
        showCookieBanner();
    }
}

// Assigning values to window object
window.onload = initializeCookieBanner();
window.cb_hideCookieBanner = hideCookieBanner;